import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { DatumValue, ResponsiveLine, Serie } from '@nivo/line';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, CircularProgress } from '@mui/material';
import { useOrdinalColorScale } from '@nivo/colors';

import { Brand, formatStat, Retailer, RoleContext, useSpot } from 'framework';
import { Row } from './layout';
import { DateRangeOption } from './date-box';
import {
  GenderSegment,
  NewDeviceSegment,
  DeviceSegment
} from './segment-selector';

export type GraphType =
  | 'widgetOpenPercentage'
  | 'sizeRecsPerUser'
  | 'sizeRecs'
  | 'sizeRecsPerWidgetUser'
  | 'completionRate'
  | 'fasletUsers'
  | 'pdpUsers'
  | 'fasletOrders'
  | 'cvr'
  | 'aov'
  | 'returns'
  | 'returnRate'
  | 'fasletItemsOrdered'
  | 'fasletItemsOrderedPercent'
  | 'fasletItemsOrderedWithFollow'
  | 'fasletItemsOrderedPercentWithFollow'
  | 'userRating'
  | 'errorRate'
  | 'orderBasedFasletUsers'
  | 'multipleSizes';

const ChartContainer = styled(Box)`
  margin: auto;
  height: 400px;
  width: 90%;
  border-radius: 18px;
  box-shadow: 3px 3px #f2f2f2;
  border: 4px solid ${p => p.theme.palette.primary.main};
  padding: ${p => p.theme.spacing(4)};
  position: relative;
  overflow-x: hidden;

  ${p => p.theme.breakpoints.down('sm')} {
    margin: ${p => p.theme.spacing(1)};
    height: 400px;
    width: 100%;
    border-radius: 18px;
    box-shadow: 0px 0px;
    border: 2px solid ${p => p.theme.palette.primary.main};
    padding: ${p => p.theme.spacing(1)};
  }

  svg {
    overflow: visible;
  }
`;

const LoadingContainer = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface EventGraphProps {
  graphType: GraphType;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  retailer: Retailer | null;
  brand?: Brand | null;
  gender?: GenderSegment;
  dateRange?: DateRangeOption;
  newDevice?: NewDeviceSegment;
  device?: DeviceSegment;
}

export function EventGraph({
  graphType,
  startDate,
  endDate,
  retailer,
  brand,
  dateRange,
  gender,
  newDevice,
  device
}: EventGraphProps) {
  const { t } = useTranslation();
  const { spot, rawQuery } = useSpot();
  const { isAdmin } = useContext(RoleContext);

  const [loading, setLoading] = useState(false);
  const [graphDatasets, setGraphDatasets] = useState<Serie[]>([]);

  const colorScale = useOrdinalColorScale({ scheme: 'category10' }, 'id');

  const updateGraphDatasets = useCallback(
    widgetEventDatapoints => {
      const dataSets =
        widgetEventDatapoints.reduce((accum, curr) => {
          if (
            !isAdmin() &&
            ['hasUsedWidgetReturnRate', 'followedAdviceReturnRate'].includes(
              curr.eventName
            )
          ) {
            return accum;
          }

          let dataSet = accum.find(item => item.id === t(curr.eventName));
          if (!dataSet) {
            dataSet = {
              id: t(curr.eventName),
              data: []
            } as Serie;
            accum.push(dataSet);
          }
          dataSet.data.push({
            y: curr.value,
            x: moment(curr.timestamp).toDate()
          });

          return accum;
        }, [] as Serie[]) ?? null;
      setGraphDatasets(dataSets);
    },
    [setGraphDatasets, t, isAdmin]
  );

  const executeGraphStatsQuery = useCallback(async () => {
    const request: {
      startDate: string;
      endDate: string;
      graphType: string;
      retailers?: string[];
      brands?: string[];
      gender?: GenderSegment;
      dateRange?: DateRangeOption;
      newDevice?: NewDeviceSegment;
      device?: DeviceSegment;
    } = {
      startDate: moment(startDate).startOf('day').toISOString(),
      endDate: moment(endDate).endOf('day').toISOString(),
      graphType,
      dateRange
    };

    if (retailer) {
      request.retailers = [encodeURIComponent(retailer.name), retailer.slug];
    } else if (spot.data?.profile?.role !== 'admin') {
      request.retailers = spot.data.retailers.reduce(
        (accum, r) => [...accum, encodeURIComponent(r.name), r.slug],
        [] as string[]
      );
    }

    if (brand) {
      request.brands = [encodeURIComponent(brand.name)];
    }

    if (gender) {
      request.gender = gender;
    }

    if (newDevice) {
      request.newDevice = newDevice;
    }

    if (device) {
      request.device = device;
    }

    setLoading(true);
    try {
      const data = await rawQuery<{ widgetEventDatapoints: [] }>(
        `analytics/graph-stats`,
        request
      );
      updateGraphDatasets(data.widgetEventDatapoints ?? []);
    } catch (e) {
      console.error(`Failed to query graph stats`, e);
      updateGraphDatasets([]);
    }
    setLoading(false);
  }, [
    spot,
    rawQuery,
    startDate,
    endDate,
    retailer,
    graphType,
    updateGraphDatasets,
    dateRange,
    brand,
    gender,
    newDevice,
    device
  ]);

  useEffect(() => {
    (async () => {
      await executeGraphStatsQuery();
    })();
  }, [executeGraphStatsQuery]);

  const percentageGraph =
    graphType === 'widgetOpenPercentage' ||
    graphType === 'completionRate' ||
    graphType === 'fasletOrders' ||
    graphType === 'cvr' ||
    graphType === 'returns' ||
    graphType === 'returnRate' ||
    graphType === 'fasletItemsOrderedPercent' ||
    graphType === 'multipleSizes';

  const colors = useCallback(
    datum => {
      return [
        'Faslet CVR',
        'Faslet AOV',
        'Faslet Returns',
        'Faslet Used Return Rate'
      ].indexOf(datum.id) !== -1
        ? '#50aa8d'
        : colorScale(datum);
    },
    [colorScale]
  );

  const yFormat = useCallback(
    (value: DatumValue) => formatStat(value as number, percentageGraph, 2),
    [percentageGraph]
  );

  const days = moment(endDate).diff(startDate, 'days');
  const formatAxisBottom = useCallback(
    (val: string) => moment(val).format(days > 32 ? 'MMM YY' : 'D MMM'),
    [days]
  );

  const formatAxisLeft = useCallback(
    (val: string) => formatStat(val, percentageGraph, percentageGraph ? 2 : 0),
    [percentageGraph]
  );

  const legends = useMemo(
    () =>
      (graphDatasets.length > 1
        ? [
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 60,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 200,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              toggleSerie: true,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]
        : []) as any[],
    [graphDatasets.length]
  );

  return graphDatasets.length ? (
    <Row>
      <ChartContainer>
        <ResponsiveLine
          data={graphDatasets}
          colors={colors}
          lineWidth={3}
          enablePoints
          isInteractive
          enableSlices="x"
          enableCrosshair
          crosshairType="cross"
          xScale={{
            type: 'time',
            min: 'auto',
            max: 'auto',
            precision: 'hour'
          }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false,
            nice: true
          }}
          yFormat={yFormat}
          curve="monotoneX"
          pointSize={10}
          pointColor="#ffffff"
          pointBorderWidth={3}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          axisBottom={{
            tickSize: 10,
            tickPadding: 10,
            tickRotation: 0,
            tickValues: Math.min(graphDatasets[0]?.data?.length, 12),
            legend: '',
            format: formatAxisBottom
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 10,
            tickRotation: -15,
            legend: '',
            tickValues: 5,
            format: formatAxisLeft
          }}
          margin={{ top: 20, right: 20, bottom: 40, left: 50 }}
          axisRight={null}
          axisTop={null}
          legends={legends}
        />
        {loading && (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        )}
      </ChartContainer>
    </Row>
  ) : (
    <ChartContainer>
      <LoadingContainer>
        {loading ? <CircularProgress /> : t('notEnoughData')}
      </LoadingContainer>
    </ChartContainer>
  );
}
