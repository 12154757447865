import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Select, MenuItem, Grid } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useSpot, Experiment, formatStat } from 'framework';
import { analyzeTestResults } from './significance-calculator';
import {
  FormRow,
  FormLabel,
  FormFieldWrapper,
  FormSection,
  FormSectionTitle
} from 'components/form-input';
import {
  DeviceSegment,
  GenderSegment,
  NewDeviceSegment,
  Row,
  Segment,
  SegmentSelector,
  SegmentValue,
  Spacer,
  UpliftButton
} from 'components';

interface ExperimentStat {
  events: number;
  users: number;
  experimentVariants: string;
  eventName: string;
  id: string;
}

export function ExperimentStats({ experiment }: { experiment: Experiment }) {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { rawQuery } = useSpot();
  const [stats, setStats] = useState<ExperimentStat[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalGroup, setTotalGroup] = useState<string>('widget_button_show');
  const [totalGroupType, setTotalGroupType] = useState<'users' | 'events'>(
    'users'
  );
  const [conversionGroup, setConversionGroup] =
    useState<string>('itemsOrdered');
  const [conversionGroupType, setConversionGroupType] = useState<
    'users' | 'events'
  >('users');
  const [result, setResult] = useState<{
    statSignificance: number;
    percentChange: number;
    conversions: ExperimentStat[];
    totalGroups: ExperimentStat[];
  } | null>(null);

  const [segment, setSegment] = useState<Segment>('all');
  const [segmentValue, setSegmentValue] = useState<SegmentValue>('all');

  const fetchStats = useCallback(async () => {
    setLoading(true);

    const gender =
      segment === 'gender' && segmentValue !== 'all'
        ? (segmentValue as GenderSegment)
        : undefined;
    const newDevice =
      segment === 'newDevice' && segmentValue !== 'all'
        ? (segmentValue as NewDeviceSegment)
        : undefined;
    const device =
      segment === 'device' && segmentValue !== 'all'
        ? (segmentValue as DeviceSegment)
        : undefined;

    try {
      const result = await rawQuery<ExperimentStat[]>(
        `experiments/${id}/stats`,
        {
          gender: gender,
          newDevice: newDevice,
          device: device
        }
      );
      setStats(
        result.map(r => ({
          ...r,
          id: `${r.eventName}-${r.experimentVariants}-${segment}-${segmentValue}`
        }))
      );
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, [id, rawQuery, segment, segmentValue]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  const handleCalculate = useCallback(() => {
    function sortVariants(a: ExperimentStat, b: ExperimentStat) {
      // If for some reason we have no variants, make sure the one with no variants is last.
      if (!a.experimentVariants) {
        return 1;
      } else if (!b.experimentVariants) {
        return -1;
      }

      if (a.experimentVariants.includes('control')) {
        return -1;
      }
      if (b.experimentVariants.includes('control')) {
        return 1;
      }
      if (a.experimentVariants.includes('variant')) {
        return 1;
      }
      if (b.experimentVariants.includes('variant')) {
        return -1;
      }
      return a.experimentVariants.localeCompare(b.experimentVariants);
    }

    const totalGroups = stats
      .filter(stat => stat.eventName === totalGroup)
      .sort(sortVariants);
    const conversions = stats
      .filter(stat => stat.eventName === conversionGroup)
      .sort(sortVariants);

    const result = analyzeTestResults(
      conversions[0]?.[conversionGroupType] ?? 0,
      totalGroups[0]?.[totalGroupType] ?? 0,
      conversions[1]?.[conversionGroupType] ?? 0,
      totalGroups[1]?.[totalGroupType] ?? 0,
      false
    );
    setResult({
      ...result,
      conversions: conversions,
      totalGroups: totalGroups
    });
  }, [stats, totalGroup, totalGroupType, conversionGroup, conversionGroupType]);

  useEffect(() => {
    handleCalculate();
  }, [handleCalculate]);

  const columns: GridColumns = [
    { field: 'eventName', headerName: t('event'), flex: 1 },
    { field: 'experimentVariants', headerName: t('variant'), flex: 1 },
    { field: 'users', headerName: t('users'), flex: 1, align: 'right' },
    { field: 'events', headerName: t('events'), flex: 1, align: 'right' }
  ];

  const handleSegmentChange = useCallback(
    (segment: Segment, value: SegmentValue) => {
      setSegment(segment);
      setSegmentValue(value);
    },
    []
  );

  return (
    <Box>
      <FormSection>
        <Row>
          <FormSectionTitle>{t('significanceCalculator')}</FormSectionTitle>
          <Spacer />
          <SegmentSelector
            segments={['gender', 'newDevice', 'device']}
            currentSegment={segment}
            currentSegmentValue={segmentValue}
            onSegmentChange={handleSegmentChange}
            retailer={
              experiment.retailers.length === 1 ? experiment.retailers[0] : null
            }
            loading={loading}
          />
        </Row>

        <Row>
          <Box>
            <FormRow>
              <FormLabel>{t('totalGroup')}</FormLabel>
              <FormFieldWrapper>
                <Select
                  value={totalGroup}
                  onChange={e => setTotalGroup(e.target.value as string)}
                  displayEmpty
                  fullWidth
                >
                  {Array.from(new Set(stats.map(stat => stat.eventName))).map(
                    event => (
                      <MenuItem key={event} value={event}>
                        {event}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormFieldWrapper>
            </FormRow>
            <FormRow>
              <FormLabel>{t('totalGroupType')}</FormLabel>
              <FormFieldWrapper>
                <Select
                  value={totalGroupType}
                  onChange={e =>
                    setTotalGroupType(e.target.value as 'users' | 'events')
                  }
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="users">{t('users')}</MenuItem>
                  <MenuItem value="events">{t('events')}</MenuItem>
                </Select>
              </FormFieldWrapper>
            </FormRow>
            <FormRow>
              <FormLabel>{t('conversionGroup')}</FormLabel>
              <FormFieldWrapper>
                <Select
                  value={conversionGroup}
                  onChange={e => setConversionGroup(e.target.value as string)}
                  displayEmpty
                  fullWidth
                >
                  {Array.from(new Set(stats.map(stat => stat.eventName))).map(
                    event => (
                      <MenuItem key={event} value={event}>
                        {event}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormFieldWrapper>
            </FormRow>
            <FormRow>
              <FormLabel>{t('conversionGroupType')}</FormLabel>
              <FormFieldWrapper>
                <Select
                  value={conversionGroupType}
                  onChange={e =>
                    setConversionGroupType(e.target.value as 'users' | 'events')
                  }
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="users">{t('users')}</MenuItem>
                  <MenuItem value="events">{t('events')}</MenuItem>
                </Select>
              </FormFieldWrapper>
            </FormRow>
          </Box>
          <Box>
            <UpliftButton
              fasletValueTitle={
                experiment.variants.find(
                  v => v.tracker === result?.conversions[0]?.experimentVariants
                )?.name ?? t('control')
              }
              nonFasletValueTitle={
                experiment.variants.find(
                  v => v.tracker === result?.conversions[1]?.experimentVariants
                )?.name ?? t('variant')
              }
              loading={loading}
              title={experiment.name}
              fasletValue={formatStat(
                result?.conversions[0]?.[conversionGroupType] ?? 0,
                false,
                0
              )}
              nonFasletValue={formatStat(
                result?.conversions[1]?.[conversionGroupType] ?? 0,
                false,
                0
              )}
              upliftValue={
                formatStat(result?.percentChange, true, 2) ?? '--.-%'
              }
              direction={result?.percentChange ?? 0}
              subtitle={`${t('statisticalSignificance')} ${result ? formatStat(result.statSignificance, true, 2) : '--.--%'}`}
              id="results"
              onClick={undefined}
              active={false}
            />
          </Box>
        </Row>
      </FormSection>
      <DataGrid
        rows={stats}
        columns={columns}
        loading={loading}
        autoHeight
        getRowId={row => row.id}
      />
    </Box>
  );
}
