import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  Box,
  TextField,
  Typography,
  Collapse,
  IconButton
} from '@mui/material';
import {
  KeyboardArrowUp as CollapseInIcon,
  KeyboardArrowDown as CollapseOutIcon
} from '@mui/icons-material';
import styled from '@emotion/styled';
import { Spacer } from './spacer';
import { Row } from './layout';

export const FormRowBlock = styled(Box)`
  display: flex;
  width: calc(260px + 360px);
  flex-wrap: wrap;
`;

export const FormRow = styled(Box)<{
  highlighted?: boolean;
  alignment?: 'baseline' | 'center';
}>`
  margin: ${p => p.theme.spacing(1, 0)};
  display: flex;
  background-color: ${p => (p.highlighted ? '#50aa8d20' : 'unset')};

  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  ${p => p.theme.breakpoints.up('sm')} {
    flex-direction: row;
    align-items: ${p => (p.alignment ? p.alignment : 'center')};
  }
`;

const InfoHelp = styled.sup`
  text-decoration: underline;
`;

const FormLabelInner = styled(Typography)<{
  hasdescription: boolean;
  size: 'narrow' | 'normal' | 'wide';
}>`
  ${p => p.theme.breakpoints.up('sm')} {
    flex: 0 0
      ${p => {
        switch (p.size) {
          case 'narrow':
            return '140px';
          case 'wide':
            return '360px';
          case 'normal':
          default:
            return '280px';
        }
      }};
    width: ${p => {
      switch (p.size) {
        case 'narrow':
          return '140px';
        case 'wide':
          return '360px';
        case 'normal':
        default:
          return '280px';
      }
    }};
    font-size: 0.9rem;
  }
  padding: ${p => p.theme.spacing(1)};
  display: inline-block;
  text-transform: capitalize;
  cursor: ${p => (p.hasdescription ? 'help' : 'default')};
  position: relative;
`;

export function FormLabel({
  children,
  info,
  id,
  color,
  size
}: PropsWithChildren<{
  id?: string;
  info?: string;
  color?: 'primary' | 'secondary' | 'warning' | 'error' | 'info' | 'success';
  size?: 'narrow' | 'normal' | 'wide';
}>) {
  return (
    <FormLabelInner
      variant="subtitle2"
      color={color ?? 'primary'}
      hasdescription={!!info}
      title={info ?? ''}
      id={id ?? ''}
      size={size ?? 'normal'}
    >
      {children}
      {info && <InfoHelp>?</InfoHelp>}
    </FormLabelInner>
  );
}

const FormInputBlock = styled(Box)`
  flex: 1 1 auto;
  display: inline-block;
`;

const FormNumberInputInner = styled(TextField)`
  margin: ${p => p.theme.spacing(1, 0)};
`;

const FormTextInputInner = styled(TextField)`
  margin: ${p => p.theme.spacing(0, 0)};
`;

export const FormFieldWrapperNarrow = styled(Box)`
  padding: ${p => p.theme.spacing(1, 1)};
  ${p => p.theme.breakpoints.up('sm')} {
    flex: 0 0 200px;
    width: 200px;
  }
  display: flex;
`;

export const FormFieldWrapper = styled(Box)<{
  size?: 'narrow' | 'normal' | 'wide';
}>`
  padding: ${p => p.theme.spacing(1, 1)};
  ${p => p.theme.breakpoints.up('sm')} {
    flex: 0 0
      ${p => {
        switch (p.size) {
          case 'narrow':
            return '200px';
          case 'wide':
            return '400px';
          case 'normal':
          default:
            return '360px';
        }
      }};
    width: ${p => {
      switch (p.size) {
        case 'narrow':
          return '200px';
        case 'wide':
          return '400px';
        case 'normal':
        default:
          return '360px';
      }
    }};
  }
  display: flex;
`;

export const FormFieldWrapperWide = styled(Box)`
  padding: ${p => p.theme.spacing(1, 1)};
  ${p => p.theme.breakpoints.up('sm')} {
    flex: 0 0 400px;
    width: 400px;
  }
  display: flex;
  align-items: center;
`;

interface FormTextInputProps {
  value: string | undefined;
  onChange?: (newValue: string) => unknown;
  validate?: (newValue: string | undefined) => boolean;
  rowsMin?: number;
  rowsMax?: number;
  rows?: number;
  multiline?: boolean;
  placeholder?: string;
  disabled?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  label?: ReactNode;
  onFocus?: () => unknown;
  onBlur?: () => unknown;
  variant?: 'standard' | 'filled' | 'outlined';
  size?: 'small' | 'medium';
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  autoFocus?: boolean;
}

const FormTextValueInner = styled(Typography)`
  ${p => p.theme.breakpoints.up('md')} {
    flex: 0 0 360px;
    width: 360px;
  }
  display: inline-block;
  padding: ${p => p.theme.spacing(1)};
`;

export function FormTextValue({
  children
}: PropsWithChildren<Record<string, unknown>>) {
  return <FormTextValueInner variant="body1">{children}</FormTextValueInner>;
}

export function FormTextInput({
  value,
  onChange,
  validate,
  ...restProps
}: FormTextInputProps) {
  const [valid, setValid] = useState(true);
  const [inputProps, setInputProps] = useState({
    endAdornment: restProps.endAdornment,
    startAdornment: restProps.startAdornment
  });

  useEffect(() => {
    setInputProps({
      endAdornment: restProps.endAdornment,
      startAdornment: restProps.startAdornment
    });
  }, [setInputProps, restProps.endAdornment, restProps.startAdornment]);

  useEffect(() => {
    if (validate && !validate(value)) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [value, setValid, validate]);

  const onInputChange = useCallback(
    e => e.target.value !== value && onChange && onChange(e.target.value),
    [value, onChange]
  );

  return (
    <FormInputBlock>
      <FormTextInputInner
        {...restProps}
        type="text"
        error={!valid}
        value={value}
        fullWidth
        InputProps={inputProps}
        onChange={onInputChange}
      />
    </FormInputBlock>
  );
}

type FormNumberInputProps = {
  value: number | undefined;
  onChange?: (newValue: number) => unknown;
  validate?: (newValue: number) => boolean;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  disabled?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  onFocus?: () => unknown;
  onBlur?: () => unknown;
};

export function FormNumberInput({
  value,
  onChange,
  validate,
  ...restProps
}: FormNumberInputProps) {
  const [valid, setValid] = useState(true);
  const [inputProps, setInputProps] = useState({
    endAdornment: restProps.endAdornment,
    startAdornment: restProps.startAdornment,
    min: restProps.min,
    max: restProps.max,
    step: restProps.step
  });

  useEffect(() => {
    setInputProps({
      endAdornment: restProps.endAdornment,
      startAdornment: restProps.startAdornment,
      min: restProps.min,
      max: restProps.max,
      step: restProps.step
    });
  }, [
    setInputProps,
    restProps.endAdornment,
    restProps.startAdornment,
    restProps.min,
    restProps.max,
    restProps.step
  ]);

  useEffect(() => {
    if (value === undefined) {
      return;
    }
    if (validate && !validate(value)) {
      setValid(false);
      return;
    }

    if (Number.isNaN(value)) {
      setValid(false);
      return;
    }

    setValid(true);
  }, [value, setValid, validate]);

  const onInputChange = useCallback(
    e =>
      Number.parseFloat(e.target.value) !== value &&
      onChange &&
      onChange(Number.parseFloat(e.target.value)),
    [value, onChange]
  );

  return (
    <FormInputBlock>
      <FormNumberInputInner
        {...restProps}
        type="number"
        fullWidth
        error={!valid}
        value={value ?? value}
        inputProps={inputProps}
        onChange={onInputChange}
      />
    </FormInputBlock>
  );
}

export const FormSectionTitle = styled(Typography)`
  padding: ${p => p.theme.spacing(1)};
  font-weight: 600;
`;

FormSectionTitle.defaultProps = {
  variant: 'subtitle1'
};

export const FormSectionSubtitle = styled(Typography)`
  padding: ${p => p.theme.spacing(2, 1)};
  font-weight: 600;
`;

FormSectionSubtitle.defaultProps = {
  variant: 'subtitle2'
};

export const FormSection = styled(Box)<{ hideBorder?: boolean }>`
  &:not(:last-child) {
    border-bottom: ${p => (p.hideBorder ? 'unset' : '2px solid #50aa8d30')};
  }

  padding: ${p => p.theme.spacing(1, 2)};

  ${p => p.theme.breakpoints.down('md')} {
    padding: ${p => p.theme.spacing(1, 0)};
    margin: 0;
  }
  overflow: hidden;
  flex: 0 0 auto;
`;

export const FormSubsection = styled(Box)<{ hideBorder?: boolean }>`
  border: ${p => (p.hideBorder ? 'unset' : '2px solid #50aa8d30')};
  border-radius: 8px;

  padding: ${p => p.theme.spacing(0, 1)};
  margin: ${p => p.theme.spacing(1, -1)};
`;

export function CollapsableFormSection({
  title,
  initiallyOpen,
  children
}: PropsWithChildren<{ title: string; initiallyOpen?: boolean }>) {
  const [open, setOpen] = useState(initiallyOpen ?? true);

  return (
    <FormSection>
      <Row>
        <FormSectionTitle>{title}</FormSectionTitle>
        <Spacer />
        <IconButton onClick={() => setOpen(o => !o)} size="large">
          {open ? <CollapseInIcon /> : <CollapseOutIcon />}
        </IconButton>
      </Row>
      <Collapse in={open}>{open && children}</Collapse>
    </FormSection>
  );
}
