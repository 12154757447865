import React, {
  HtmlHTMLAttributes,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { Autocomplete, IconButton, TextField, Typography } from '@mui/material';
import { matchSorter } from 'match-sorter';

import { LocalOfferRounded as GoToBrandIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import { Brand, RetailerItem, RoleContext, useSpot } from '../framework';
import { CenteredRow } from './layout';
import { Gap } from './spacer';
import unknownLogo from '../images/spiral-logo.png';

const Wrapper = styled.div`
  flex: 0 0 360px;

  ${p => p.theme.breakpoints.down('md')} {
    flex: 0 0 300px;
  }

  display: flex;
  align-items: center;
`;

const BrandNameRow = styled(CenteredRow)`
  flex-wrap: nowrap;
  flex: 1 1 auto;
`;

const Logo = styled.div<{ image?: string }>`
  height: 32px;
  background: url('${p => p.image}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex: 0 0 50px;
  margin-right: 12px;
  margin-left: 4px;
`;

const BrandName = styled(Typography)`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const StyledInput = styled(TextField)`
  color: black;
  background-color: white;
  border-radius: 5px;
`;

const GoToBrandButton = styled(IconButton)`
  color: ${p => p.theme.palette.primary.main};
`;

export interface BrandSelectorProps {
  includeAllBrands?: boolean;
  onBrandChanged?: (brand: Brand | null) => unknown;
  selectedBrand: Brand | null;
  initialBrandSlug?: string; // to allow passing url values in
  disabled?: boolean;
  retailer?: RetailerItem | null;
}

export const allBrandsBrandId = -1;

const portalLogo = unknownLogo;

export const allBrandsBrand: Brand = {
  name: 'allBrands',
  slug: 'all-brands',
  id: allBrandsBrandId,
  logo: portalLogo,
  logoS3: null,
  retailers: []
};

export function BrandSelector({
  includeAllBrands,
  onBrandChanged,
  selectedBrand,
  initialBrandSlug,
  disabled: propDisabled,
  retailer
}: BrandSelectorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { spot, query, data, loading } = useSpot();

  const { isAdmin } = useContext(RoleContext);

  const disabled = propDisabled || loading || !onBrandChanged;

  useEffect(() => {
    (async () => {
      if (!spot.data?.brands) {
        await query('brand/', {}, ['brands']);
      }

      if (
        spot.data.brands?.length === 1 &&
        selectedBrand?.id !== spot.data.brands?.[0]?.id
      ) {
        onBrandChanged && onBrandChanged(spot.data.brands?.[0] ?? null);
      } else if (initialBrandSlug) {
        const foundBrand = spot.data.brands?.find(
          b => b.slug === initialBrandSlug
        );
        onBrandChanged && onBrandChanged(foundBrand ?? null);
      } else if (!includeAllBrands) {
        onBrandChanged && onBrandChanged(spot.data.brands?.[0] ?? null);
      }
    })();
  }, []);

  const baseBrands = includeAllBrands ? [allBrandsBrand] : ([] as Brand[]);

  const defaultBrand = includeAllBrands ? allBrandsBrand : null;

  const extendedBrands = baseBrands.concat(
    retailer
      ? data?.brands?.filter(b =>
          b.retailers.find(r => r.slug === retailer?.slug)
        )
      : data?.brands ?? []
  );

  const brandChanged = useCallback(
    (event: unknown, newBrand: Brand | null) => {
      onBrandChanged &&
        onBrandChanged(newBrand?.id === allBrandsBrandId ? null : newBrand);
    },
    [onBrandChanged]
  );

  const onGoToBrand = useCallback(() => {
    navigate(`/brand/${selectedBrand?.slug}`);
  }, [navigate, selectedBrand]);

  const getOptionLabel = useCallback(
    (b: Brand) => (b.id === allBrandsBrandId ? t(b.name) : b.name ?? '-'),
    [t]
  );

  const renderInput = useCallback(
    (params: any) => (
      <StyledInput
        {...params}
        hiddenLabel
        fullWidth
        size="small"
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,
          startAdornment: <Logo image={selectedBrand?.logo ?? portalLogo} />
        }}
      />
    ),
    [selectedBrand]
  );

  const renderOption = useCallback(
    (props: HtmlHTMLAttributes<HTMLLIElement>, b: Brand) => (
      <li {...props}>
        <BrandNameRow>
          <Logo image={b.logo ?? portalLogo} />
          <BrandName>
            {b.id === allBrandsBrandId ? t(b.name) : b.name ?? '-'}
          </BrandName>
        </BrandNameRow>
      </li>
    ),
    [t]
  );

  const getOptionSelected = useCallback(
    (r1: Brand, r2: Brand) => r1.slug === r2.slug,
    []
  );

  const filterOptions = (options, { inputValue }) =>
    matchSorter<Brand>(options, inputValue, { keys: ['name', 'slug'] }).slice(
      0,
      50
    );

  return (
    <Wrapper>
      <Autocomplete
        disabled={disabled}
        fullWidth
        onChange={brandChanged}
        value={selectedBrand ?? defaultBrand}
        disableClearable={!defaultBrand}
        selectOnFocus
        options={extendedBrands}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={getOptionSelected}
        filterOptions={filterOptions}
        renderInput={renderInput}
        renderOption={renderOption}
      />
      <Gap size={1} />
      {isAdmin() && (
        <GoToBrandButton
          onClick={onGoToBrand}
          disabled={!selectedBrand}
          title={t('gotoBrand')}
        >
          <GoToBrandIcon color="inherit" />
        </GoToBrandButton>
      )}
    </Wrapper>
  );
}
