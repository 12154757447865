import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { Box, Typography, Grid, IconButton, Button } from '@mui/material';
import styled from '@emotion/styled';
import {
  ContentCopyRounded as CopyIcon,
  BlockRounded as BlockCustomerIcon,
  CheckCircleRounded as UnblockCustomerIcon
} from '@mui/icons-material';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import { ResponsivePie } from '@nivo/pie';
import { useOrdinalColorScale } from '@nivo/colors';
import { useTranslation } from 'react-i18next';
import copyToClipboard from 'copy-to-clipboard';

import {
  Gap,
  FormSection,
  FormRow,
  FormFieldWrapper,
  FormTextValue,
  FormLabel,
  RetailerLink,
  Row,
  Notification,
  FormSectionTitle,
  Spacer,
  BrandSelector
} from 'components';
import {
  Brand,
  Profile,
  RepeatReturner,
  Retailer,
  RoleContext,
  formatStat,
  useSpot
} from 'framework';
import moment from 'moment';
import { reasonStrings } from './common';

const ChartContainer = styled(Box)`
  height: 400px;
  width: 100%;
  position: relative;
  overflow: hidden;
  flex: 0 0 400px;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 600px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
`;

const GridParent = styled(Grid)`
  height: 600px;
`;

export function RepeatReturners({
  startDate,
  endDate,
  retailer
}: {
  startDate: moment.Moment;
  endDate: moment.Moment;
  retailer: Retailer | null;
}) {
  const { t } = useTranslation();
  const { spot, query, command, loading, data } = useSpot();
  const { isAdmin } = useContext(RoleContext);
  const [copied, setCopied] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);

  const [selectedItem, setSelectedItem] = useState<RepeatReturner>();
  const [returnReasons, setReturnReasons] = useState<
    { id: string; label: string; value: number }[]
  >([]);

  const returnsColorScale = useOrdinalColorScale(
    { scheme: 'category10' },
    'id'
  );

  const [returnReasonColors] = useState<Record<string, string>>(
    reasonStrings.reduce(
      (accum, r) => ({
        ...accum,
        [r]: returnsColorScale({ id: r })
      }),
      {}
    )
  );

  const fetchProductReturns = useCallback(async () => {
    const params: {
      startDate: string;
      endDate: string;
      retailers?: string[];
      brands?: string[];
    } = {
      startDate: moment(startDate).startOf('day').toISOString(),
      endDate: moment(endDate).endOf('day').toISOString()
    };

    if (retailer) {
      params.retailers = [encodeURIComponent(retailer.name), retailer.slug];
    } else if (spot.data?.profile?.role !== 'admin') {
      params.retailers = spot.data.retailers.reduce(
        (accum, r) => [...accum, encodeURIComponent(r.name), r.slug],
        [] as string[]
      );
    }

    if (selectedBrand) {
      params.brands = [encodeURIComponent(selectedBrand.name)];
    }

    await query('analytics/repeat-returners', params, [
      'returns',
      'repeatReturners'
    ]);
  }, [startDate, endDate, retailer, selectedBrand, query]);

  useEffect(() => {
    setSelectedItem(undefined);
    startDate && endDate && fetchProductReturns();
  }, [startDate, endDate, fetchProductReturns]);

  const onRowClicked = useCallback(
    async ({ row }) => {
      await query(`retailer/${row.shopId}/customer/${row.userId}`, {}, [
        'customers',
        row.shopId,
        row.userId
      ]);
      setSelectedItem(row);
    },
    [setSelectedItem]
  );

  const blockCustomer = useCallback(async () => {
    if (selectedItem) {
      await command(
        `retailer/${selectedItem.shopId}/customer/${selectedItem.userId}`,
        { status: 'blocked' }
      );
      await query(
        `retailer/${selectedItem.shopId}/customer/${selectedItem.userId}`,
        {},
        ['customers', selectedItem.shopId, selectedItem.userId]
      );
    }
  }, [selectedItem]);

  const unblockCustomer = useCallback(async () => {
    if (selectedItem) {
      await command(
        `retailer/${selectedItem.shopId}/customer/${selectedItem.userId}`,
        { status: 'active' }
      );
      await query(
        `retailer/${selectedItem.shopId}/customer/${selectedItem.userId}`,
        {},
        ['customers', selectedItem.shopId, selectedItem.userId]
      );
    }
  }, [selectedItem]);

  useEffect(() => {
    const calculatedReasonBreakdown =
      selectedItem?.reasons?.reduce(
        (accum, current) => {
          const found = accum.find(a => a.id === reasonStrings[current - 1]);
          if (found) {
            found.value++;
          } else {
            accum.push({
              id: reasonStrings[current - 1],
              label: reasonStrings[current - 1],
              value: 1
            });
          }
          return [...accum];
        },
        [] as { id: string; value: number; label: string }[]
      ) ?? [];

    setReturnReasons(calculatedReasonBreakdown);
  }, [selectedItem, setReturnReasons]);

  const handleCopy = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const copyContent = (event.currentTarget as Node)?.parentNode
        ?.textContent;
      if (copyContent) {
        copyToClipboard(copyContent);
        setCopied(true);
      }
    },
    []
  );

  const columns: GridColumns = [
    {
      field: 'userId',
      headerName: t('fasletUserId'),
      flex: 1,
      hide: !!selectedItem
    },
    {
      field: 'shopId',
      headerName: t('shopId'),
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <RetailerLink retailer={params.value as string} />
      ),
      hide: !isAdmin()
    },
    {
      field: 'orders',
      headerName: t('orders'),
      type: 'number',
      minWidth: 150
    },
    {
      field: 'items',
      headerName: t('itemsOrdered'),
      type: 'number',
      minWidth: 150
    },
    {
      field: 'returns',
      headerName: t('itemsReturned'),
      type: 'number',
      minWidth: 150
    },
    {
      field: 'sizeRelatedReturns',
      headerName: t('sizeRelatedReturns'),
      type: 'number',
      minWidth: 150
    },
    {
      field: 'returnRate',
      headerName: t('returnRate'),
      type: 'number',
      renderCell: (params: GridCellParams) =>
        formatStat((params.value as number) * 100, true, 2),
      minWidth: 150
    }
  ];

  const orderItems = useMemo(
    () =>
      selectedItem
        ? selectedItem.orderIds.reduce(
            (accum, curr, index) => [
              ...accum,
              {
                id: `${index}-${curr}`,
                orderId: curr,
                productId: selectedItem.productIds[index],
                product: selectedItem.products[index],
                variant: selectedItem.variants[index],
                brand: selectedItem.brands[index],
                time: selectedItem.times[index],
                result: selectedItem.results[index]
              }
            ],
            [] as {
              id: string;
              orderId: string;
              productId: string;
              product: string;
              variant: string;
              brand: string;
              time: string;
              result: string[];
            }[]
          )
        : null,
    [selectedItem]
  );

  const distinctProfiles = useMemo(
    () =>
      selectedItem?.profiles
        ?.reduce((accum, curr) => (curr ? [...accum, ...curr] : accum), [])
        .filter(p => !!p.h || !!p.rsb)
        .reduce((accum, curr) => {
          if (
            !accum.find(
              a =>
                a.h === curr.h &&
                a.w === curr.w &&
                a.hips === curr.hips &&
                a.chest === curr.chest &&
                a.belly === curr.belly &&
                a.fit === curr.fit &&
                a.rsb === curr.rsb &&
                a.rss === curr.rss &&
                a.fw === curr.fw
            )
          ) {
            accum.push(curr);
          }
          return accum;
        }, [] as Profile[]) ?? [],
    [selectedItem]
  );

  const orderItemsHeaders: GridColumns = [
    {
      field: 'orderId',
      headerName: t('orderId'),
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <>
          <IconButton onClick={handleCopy} size="small">
            <CopyIcon />
          </IconButton>
          {params.value}
        </>
      )
    },
    {
      field: 'product',
      headerName: t('product'),
      flex: 1
    },
    {
      field: 'variant',
      headerName: t('variant'),
      flex: 0.5
    },
    {
      field: 'result',
      headerName: t('result'),
      flex: 0.5,
      hide: !isAdmin(),
      renderCell: (params: GridCellParams) => params.value.join(', ')
    },
    {
      field: 'time',
      headerName: t('orderDate'),
      maxWidth: 150,
      renderCell: (params: GridCellParams) => moment(params.value).format('ll')
    }
  ];

  const returnerRows =
    spot.data?.returns?.repeatReturners?.repeatReturners ?? [];

  const customerStatus = useMemo(
    () =>
      selectedItem
        ? data.customers?.[selectedItem.shopId]?.[selectedItem.userId]?.status
        : 'active',
    [selectedItem, data.customers, loading]
  );

  return (
    <Box>
      <Row>
        <Box>
          <Typography variant="h5">{t('repeatReturners')}</Typography>
          {!selectedItem && (
            <Typography variant="caption" color="primary">
              {t('repeatReturnersInfo')}
            </Typography>
          )}
        </Box>
        <Spacer />
        <BrandSelector
          selectedBrand={selectedBrand}
          includeAllBrands
          onBrandChanged={setSelectedBrand}
          retailer={retailer}
          disabled={loading}
        />
      </Row>
      <Gap size={2} />
      <GridParent container>
        <Grid item xs={selectedItem ? 7 : 12}>
          <DataGrid
            rows={returnerRows}
            columns={columns}
            getRowId={row => row.userId}
            onRowClick={onRowClicked}
            loading={loading}
            disableColumnMenu
            disableColumnSelector
            autoPageSize
            columnBuffer={2}
            headerHeight={40}
            rowHeight={52}
            initialState={{
              sorting: {
                sortModel: [{ field: 'returns', sort: 'desc' }]
              }
            }}
          />
        </Grid>
        {selectedItem && (
          <Grid item xs={5}>
            <Container>
              <Row>
                <Gap />
                <Typography variant="h6">
                  {t('fasletUserId')}: {selectedItem.userId}
                </Typography>
              </Row>
              <Gap />
              <Typography variant="subtitle2" color="primary" align="center">
                {t('returnsBreakdown')}
              </Typography>
              <ChartContainer>
                <ResponsivePie
                  data={returnReasons}
                  innerRadius={0.4}
                  padAngle={3}
                  cornerRadius={8}
                  activeOuterRadiusOffset={8}
                  valueFormat={val => formatStat(val, false, 0)}
                  colors={r => returnReasonColors[r.id]}
                  arcLabelsTextColor="#fff"
                  arcLabelsSkipAngle={10}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={3}
                  arcLinkLabelsDiagonalLength={8}
                  arcLinkLabelsStraightLength={32}
                  arcLinkLabelsColor={{ from: 'color' }}
                  margin={{
                    top: 40,
                    right: 80,
                    bottom: 40,
                    left: 80
                  }}
                  borderWidth={5}
                  borderColor="rgba(255,255,255,0)"
                />
              </ChartContainer>
              <FormSection>
                <FormRow>
                  <FormLabel>{t('itemsOrdered')}</FormLabel>
                  <FormFieldWrapper size="narrow">
                    <FormTextValue>
                      {formatStat(selectedItem.items)}
                    </FormTextValue>
                  </FormFieldWrapper>
                </FormRow>
                <FormRow>
                  <FormLabel>{t('itemsReturned')}</FormLabel>
                  <FormFieldWrapper size="narrow">
                    <FormTextValue>
                      {formatStat(selectedItem.returns)}
                    </FormTextValue>
                  </FormFieldWrapper>
                </FormRow>
                <FormRow>
                  <FormLabel>{t('returnRate')}</FormLabel>
                  <FormFieldWrapper size="narrow">
                    <FormTextValue>
                      {formatStat(selectedItem.returnRate * 100, true, 2)}
                    </FormTextValue>
                  </FormFieldWrapper>
                </FormRow>
                {isAdmin() && (
                  <>
                    <FormRow>
                      <FormLabel>{t('hasUsedWidget')}</FormLabel>
                      <FormFieldWrapper size="narrow">
                        <FormTextValue>
                          {formatStat(
                            selectedItem.hasUsedWidgetRate * 100,
                            true,
                            2
                          )}
                        </FormTextValue>
                      </FormFieldWrapper>
                    </FormRow>
                    <FormRow>
                      <FormLabel>{t('followedAdvice')}</FormLabel>
                      <FormFieldWrapper size="narrow">
                        <FormTextValue>
                          {formatStat(
                            selectedItem.followedAdviceRate * 100,
                            true,
                            2
                          )}
                        </FormTextValue>
                      </FormFieldWrapper>
                    </FormRow>
                    <FormRow>
                      <FormLabel>{t('uniqueProfiles')}</FormLabel>
                      <FormFieldWrapper size="narrow">
                        <FormTextValue>
                          {formatStat(distinctProfiles.length, false, 0)}
                        </FormTextValue>
                      </FormFieldWrapper>
                    </FormRow>
                    <FormRow>
                      <FormLabel></FormLabel>
                      <FormFieldWrapper size="narrow">
                        {customerStatus === 'active' ? (
                          <Button
                            startIcon={<BlockCustomerIcon />}
                            variant="contained"
                            color="error"
                            disabled={loading}
                            onClick={blockCustomer}
                          >
                            {t('blockCustomer')}
                          </Button>
                        ) : (
                          <Button
                            startIcon={<UnblockCustomerIcon />}
                            variant="contained"
                            color="success"
                            disabled={loading}
                            onClick={unblockCustomer}
                          >
                            {t('unblockCustomer')}
                          </Button>
                        )}
                      </FormFieldWrapper>
                    </FormRow>
                  </>
                )}
              </FormSection>
              {!!distinctProfiles.length && (
                <FormSection>
                  <FormSectionTitle>{t('lastUsedProfile')}</FormSectionTitle>
                  <FormRow>
                    <FormLabel>{t('height')}</FormLabel>
                    <FormFieldWrapper size="narrow">
                      <FormTextValue>
                        {distinctProfiles[distinctProfiles.length - 1].h} cm
                      </FormTextValue>
                    </FormFieldWrapper>
                  </FormRow>
                  <FormRow>
                    <FormLabel>{t('weight')}</FormLabel>
                    <FormFieldWrapper size="narrow">
                      <FormTextValue>
                        {distinctProfiles[distinctProfiles.length - 1].w} kg
                      </FormTextValue>
                    </FormFieldWrapper>
                  </FormRow>
                  <FormRow>
                    <FormLabel>{t('chest')}</FormLabel>
                    <FormFieldWrapper size="narrow">
                      <FormTextValue>
                        {t('chestOptions', { returnObjects: true })[
                          distinctProfiles[distinctProfiles.length - 1].chest -
                            1
                        ] ?? '--'}
                      </FormTextValue>
                    </FormFieldWrapper>
                  </FormRow>
                  <FormRow>
                    <FormLabel>{t('belly')}</FormLabel>
                    <FormFieldWrapper size="narrow">
                      <FormTextValue>
                        {t('bellyOptions', { returnObjects: true })[
                          distinctProfiles[distinctProfiles.length - 1].belly -
                            1
                        ] ?? '--'}
                      </FormTextValue>
                    </FormFieldWrapper>
                  </FormRow>
                  <FormRow>
                    <FormLabel>{t('hips')}</FormLabel>
                    <FormFieldWrapper size="narrow">
                      <FormTextValue>
                        {t('hipsOptions', { returnObjects: true })[
                          distinctProfiles[distinctProfiles.length - 1].hips - 1
                        ] ?? '--'}
                      </FormTextValue>
                    </FormFieldWrapper>
                  </FormRow>
                </FormSection>
              )}
              {orderItems && (
                <DataGrid
                  rows={orderItems}
                  columns={orderItemsHeaders}
                  loading={loading}
                  disableSelectionOnClick
                  disableColumnMenu
                  disableColumnSelector
                  pageSize={10}
                  headerHeight={40}
                  rowHeight={52}
                  autoHeight
                  columnBuffer={2}
                />
              )}
            </Container>
          </Grid>
        )}
      </GridParent>
      {copied && (
        <Notification
          onClose={() => setCopied(false)}
          title={t('copied')}
          severity="info"
          duration={3000}
        />
      )}
    </Box>
  );
}
