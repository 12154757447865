import styled from '@emotion/styled';
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  IconButton
} from '@mui/material';
import { EditRounded as EditIcon } from '@mui/icons-material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import moment, { Moment } from 'moment/moment';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

import { Gap } from './spacer';
import { CenteredRow } from './layout';
import { CustomDialog } from './dialogs';
import { RoleContext } from 'framework';

export type DateRangeOption =
  | 'lastSevenDays'
  | 'lastThirtyDays'
  | 'lastMonth'
  | 'thisMonth'
  | 'lastYear'
  | 'thisYear'
  | 'last12Months'
  | 'allTime'
  | 'custom'
  | 'twoMonthsAgo';

const defaultRanges: DateRangeOption[] = [
  'lastSevenDays',
  'lastThirtyDays',
  'lastMonth',
  'twoMonthsAgo',
  'thisMonth',
  'lastYear',
  'thisYear',
  'custom'
];

const SelectBox = styled(Box)`
  width: 100%;
  position: relative;
`;

const DatePickerBox = styled(CenteredRow)`
  margin-top: ${p => p.theme.spacing(2)};
  flex-wrap: nowrap;
`;

const FilterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 0 1 300px;
  align-items: flex-end;
`;

export interface DateBoxProps {
  disabled?: boolean;
  onStartChange: (startDate: moment.Moment) => unknown;
  onEndChange: (endDate: moment.Moment) => unknown;
  onRangeChange?: (range: DateRangeOption) => unknown;
  allowedRanges?: DateRangeOption[];
  defaultRange?: DateRangeOption;
  includeToday?: boolean;
  defaultStart?: moment.Moment;
  defaultEnd?: moment.Moment;
}

const getStartDateFromRange = (range: DateRangeOption) => {
  if (range === 'lastSevenDays') {
    return moment.utc().subtract(7, 'day').startOf('day');
  }
  if (range === 'lastThirtyDays') {
    return moment.utc().subtract(30, 'day').startOf('day');
  }
  if (range === 'lastMonth') {
    return moment.utc().subtract(1, 'month').startOf('month');
  }
  if (range === 'twoMonthsAgo') {
    return moment.utc().subtract(2, 'month').startOf('month');
  }
  if (range === 'thisMonth') {
    return moment.utc().startOf('month').startOf('day');
  }
  if (range === 'lastYear') {
    return moment.utc().subtract(1, 'year').startOf('year');
  }
  if (range === 'thisYear') {
    return moment.utc().startOf('year').startOf('day');
  }
  if (range === 'last12Months') {
    return moment.utc().subtract(12, 'month').startOf('month');
  }
  if (range === 'allTime') {
    return moment.utc('2022-01-01', 'YYYY-MM-DD');
  }
  return null;
};

const getEndDateFromRange = (
  range: DateRangeOption,
  includeToday?: boolean
) => {
  if (range === 'lastSevenDays') {
    return moment
      .utc()
      .subtract(includeToday ? 0 : 1, 'day')
      .endOf('day');
  }
  if (range === 'lastThirtyDays') {
    return moment
      .utc()
      .subtract(includeToday ? 0 : 1, 'day')
      .endOf('day');
  }
  if (range === 'lastMonth') {
    return moment.utc().subtract(1, 'month').endOf('month');
  }
  if (range === 'twoMonthsAgo') {
    return moment.utc().subtract(2, 'month').endOf('month');
  }
  if (range === 'thisMonth') {
    return moment
      .utc()
      .subtract(includeToday ? 0 : 1, 'day')
      .endOf('day');
  }
  if (range === 'lastYear') {
    return moment.utc().subtract(1, 'year').endOf('year');
  }
  if (range === 'thisYear') {
    return moment
      .utc()
      .subtract(includeToday ? 0 : 1, 'day')
      .endOf('day');
  }
  if (range === 'last12Months') {
    return moment
      .utc()
      .subtract(includeToday ? 0 : 1, 'day')
      .endOf('day');
  }
  if (range === 'allTime') {
    return moment
      .utc()
      .subtract(includeToday ? 0 : 1, 'day')
      .endOf('day');
  }
  return null;
};

export function DateBox({
  disabled,
  onStartChange,
  onEndChange,
  onRangeChange,
  allowedRanges,
  defaultRange,
  defaultStart,
  defaultEnd,
  includeToday
}: DateBoxProps) {
  const { t } = useTranslation();
  const { isAdmin } = useContext(RoleContext);
  const maxRangeInMonths = 12;
  const allowedDateRanges = useRef(allowedRanges ?? [...defaultRanges]);

  const [currentDate, setCurrentDate] = useState<DateRangeOption>(
    defaultRange ?? 'lastMonth'
  );

  const [startDate, setStartDate] = useState<moment.Moment>(
    defaultStart ??
      getStartDateFromRange(defaultRange ?? 'lastMonth') ??
      moment.utc().subtract(1, 'month').startOf('month')
  );
  const [endDate, setEndDate] = useState<moment.Moment>(
    defaultEnd ??
      getEndDateFromRange(defaultRange ?? 'lastMonth', includeToday) ??
      moment.utc().startOf('month')
  );

  const [showCustomRangeDialog, setShowCustomRangeDialog] =
    useState<boolean>(false);

  const [customCalendar, setCustomCalendar] = useState<boolean>(
    currentDate === 'custom'
  );

  const onEndDateChange = useCallback(
    v => {
      setEndDate(v);

      if (v && startDate && v.diff(startDate, 'months') >= maxRangeInMonths) {
        setStartDate(moment.utc(v).subtract(maxRangeInMonths, 'months'));
      }
    },
    [setEndDate, setStartDate, startDate, maxRangeInMonths]
  );

  const onStartDateChange = useCallback(
    v => {
      setStartDate(v);

      if (v && endDate && endDate.diff(v, 'months') >= maxRangeInMonths) {
        setEndDate(moment.utc(v).add(maxRangeInMonths, 'months'));
      }
    },
    [setEndDate, setStartDate, endDate, maxRangeInMonths]
  );

  useEffect(() => {
    onStartChange(startDate);
    onEndChange(endDate);
    onRangeChange?.(currentDate);
  }, [currentDate, startDate, endDate]);

  const onFancyDateSelect = useCallback(
    (event: SelectChangeEvent<DateRangeOption>) => {
      const targetRange = event.target.value as DateRangeOption;
      if (allowedDateRanges.current.indexOf(targetRange) === -1) {
        return;
      }

      const newStartDate = getStartDateFromRange(targetRange);
      if (newStartDate) {
        setStartDate(newStartDate);
      }

      const newEndDate = getEndDateFromRange(targetRange, includeToday);
      if (newEndDate) {
        setEndDate(newEndDate);
      }

      setCurrentDate(targetRange);
      setCustomCalendar(targetRange === 'custom');
      setShowCustomRangeDialog(targetRange === 'custom');
    },
    [setCustomCalendar, setCurrentDate, allowedDateRanges, includeToday]
  );

  return (
    <FilterBox>
      <SelectBox>
        <Select
          disabled={disabled}
          fullWidth
          value={currentDate ?? ''}
          onChange={onFancyDateSelect}
        >
          {allowedDateRanges.current.map(el => (
            <MenuItem key={el} value={el}>
              {t(el)}
            </MenuItem>
          ))}
        </Select>
      </SelectBox>
      {currentDate === 'custom' && (
        <>
          <Gap size={1} />
          <CenteredRow>
            <Typography variant="subtitle2">
              {startDate.format('LL')} - {endDate.format('LL')}
            </Typography>
            <Gap size={1} />
            <IconButton
              onClick={() => setShowCustomRangeDialog(true)}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </CenteredRow>
        </>
      )}
      {showCustomRangeDialog && (
        <CustomDateDialog
          onClose={() => setShowCustomRangeDialog(false)}
          onConfirm={v => {
            onStartDateChange(v.startDate);
            onEndDateChange(v.endDate);
            setShowCustomRangeDialog(false);
          }}
          disabled={disabled}
          dateRange={{ startDate, endDate }}
          maxDays={isAdmin() ? 92 : 31}
        />
      )}
    </FilterBox>
  );
}

interface DateRange {
  startDate?: Moment;
  endDate?: Moment;
}

interface CustomDateDialogProps {
  onClose: () => void;
  onConfirm: (dateRange: DateRange) => void;
  dateRange?: DateRange;
  disabled?: boolean;
  maxDays: number;
}

function CustomDateDialog(props: CustomDateDialogProps) {
  const { onConfirm, dateRange, disabled, maxDays } = props;

  const { t } = useTranslation();

  const onRequest = useCallback(
    async (v: DateRange) => {
      v && onConfirm(v);
    },
    [onConfirm]
  );

  const dateRangeValid = v =>
    v?.endDate && v?.startDate
      ? v.endDate?.diff(v.startDate, 'days') < maxDays
      : false;

  function renderControl(
    setValue: (val?: DateRange) => void,
    value?: DateRange
  ) {
    const yesterday = moment.utc().subtract(1, 'day');

    return (
      <>
        <Typography variant="subtitle2">
          {t('customDateRangeSubtitle1', { days: maxDays })}
        </Typography>
        <Gap size={1} />
        <Typography variant="subtitle2">
          {t('customDateRangeSubtitle2')}
        </Typography>
        <Gap size={4} />
        <DatePickerBox>
          <DatePicker
            disabled={disabled}
            label={t('startDate')}
            value={value?.startDate ? moment(value.startDate, 'DD-MM') : null}
            onChange={newValue =>
              newValue && setValue({ ...value, startDate: newValue })
            }
            renderInput={params => (
              <TextField {...params} error={!value?.startDate} />
            )}
            minDate={moment('2023-01-01T00:00:00.000Z')}
            maxDate={yesterday}
            inputFormat="D MMM YYYY"
            views={['month', 'day']}
            disableMaskedInput
          />
          <Gap size={1} />
          <DatePicker
            disabled={disabled}
            label={t('endDate')}
            value={value?.endDate ? moment(value.endDate, 'DD-MM') : null}
            onChange={newValue =>
              newValue && setValue({ ...value, endDate: newValue })
            }
            renderInput={params => (
              <TextField {...params} error={!value?.endDate} />
            )}
            minDate={moment('2023-01-01T00:00:00.000Z')}
            maxDate={yesterday}
            inputFormat="D MMM YYYY"
            views={['month', 'day']}
            disableMaskedInput
          />
        </DatePickerBox>
      </>
    );
  }

  return CustomDialog<DateRange>({
    ...props,
    dialogOpen: true,
    initialValue: dateRange,
    title: t('customDateRangeTitle'),
    canSubmit: dateRangeValid,
    onSubmit: onRequest,
    acceptLabel: t('request'),
    renderControl
  });
}
