import React, {
  Fragment,
  useCallback,
  useState,
  useEffect,
  lazy,
  Suspense,
  useContext,
  useRef
} from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import { Error } from 'spot-store';
import { useTranslation, Trans } from 'react-i18next';

import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Autocomplete,
  Switch,
  Alert
} from '@mui/material';
import {
  ArrowBackRounded as BackIcon,
  CancelRounded as CancelIcon,
  SaveRounded as SaveIcon,
  ExitToAppRounded as PopoutIcon,
  RocketLaunchRounded as ActivateIcon
} from '@mui/icons-material';

import {
  Container,
  FileInputButton,
  Gap,
  Line,
  Row,
  Spacer,
  Tabs,
  FormSection,
  FormRow,
  FormLabel,
  FormSectionTitle,
  CenteredRow,
  Filler,
  FormFieldWrapperWide,
  WidgetConfigurationBasic,
  StepConfiguration,
  FormFieldWrapper
} from 'components';
import {
  BillingDetails,
  Brand,
  ExtendedRetailer,
  openInNewTab,
  useSpot,
  WidgetConfiguration,
  useTabFromQuerystring,
  RoleContext,
  getCdnPath,
  getS3Path
} from 'framework';
import { WidgetPreview as LivePreview } from '../signup/widget-preview';

import { ProductFeedConfiguration } from './product-feed-configuration';
import { flattenLocJson, inflateLocJson } from './localization-helpers';

const WidgetIntegration = lazy(() => import('./widget-integration'));
const WidgetEffects = lazy(() => import('./widget-effects'));
const PricePlan = lazy(() => import('./price-plan'));
const AdvancedSettings = lazy(() => import('./advanced-settings'));
const LocalizationEditor = lazy(() => import('./localization-editor'));

import newUiPromo from '../../images/new-ui.webp';

const NonWrappingRow = styled(CenteredRow)`
  flex-wrap: nowrap;
  flex: 1 1 auto;
`;

const PromoImageContainer = styled(Box)`
  flex: 0 1 800px;
`;

const PromoImage = styled.img`
  width: 100%;
  height: auto;
`;

const NewUiToggleContainer = styled(Box)`
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${p => p.theme.spacing(2)};
`;

const ToggleButton = styled(Button)`
  width: 300px;
`;

const StyledChip = styled(Chip)`
  margin-top: ${p => p.theme.spacing(1)};
  margin-right: ${p => p.theme.spacing(1)};
`;

const ChipsFieldWrapper = styled(FormFieldWrapperWide)`
  padding: ${p => p.theme.spacing(1, 1)};
  flex-wrap: wrap;
`;

const StyledFormSection = styled(FormSection)`
  width: 100%;
  position: relative;
  min-height: 350px;
`;

const HalfWidthContainer = styled(Container)`
  flex: 0 0 60%;
`;

const PreviewBar = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 40%;
  position: relative;
  overflow: hidden;
`;

const StickyWrapper = styled.div`
  position: relative;
  ${p => p.theme.breakpoints.up('lg')} {
    max-width: 520px;
  }
`;

const TitleField = styled(TextField)`
  input {
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.235;
  }
`;

const StyledLink = styled.span`
  text-decoration: underline;
`;

const ButtonlessUpsell = styled.div`
  cursor: pointer;
  h3 {
    text-align: center;
    color: ${p => p.theme.palette.primary.main};
  }
`;

const StepConfigFormSection = styled(FormSection)`
  position: relative;
  height: 600px;
`;

export interface SingleRetailerProps {
  displayErrors: (errors: Error[]) => unknown;
  updateRetailer: (retailer: ExtendedRetailer | null) => unknown;
  updateWidgetConfiguration: (
    widgetConfiguration: WidgetConfiguration | null | undefined
  ) => unknown;
  selectedRetailer: ExtendedRetailer;
  widgetConfiguration: WidgetConfiguration | null | undefined;
  onBack: () => Promise<unknown>;
}

export function SingleRetailer({
  displayErrors,
  updateRetailer,
  updateWidgetConfiguration,
  selectedRetailer,
  widgetConfiguration,
  onBack
}: SingleRetailerProps) {
  const { t } = useTranslation();

  const [pricePlan, setPricePlan] = useState<BillingDetails>();
  const [localization, setLocalization] = useState<
    Record<string, Record<string, string>>
  >({});

  const { query, raw, spot, data, command } = useSpot();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { tab, setTab } = useTabFromQuerystring();

  const { isAdmin } = useContext(RoleContext);

  const parent = useRef<HTMLElement | null>(null);
  const widgetPreview = useRef<HTMLDivElement | null>(null);

  const handleButtonlessUpsell = useCallback(() => {
    openInNewTab(
      `mailto:sales@faslet.me?subject=Enable Buttonless Mode&body=I'd like to enable Buttonless mode in my store`
    );
  }, []);

  useEffect(() => {
    query('global-configuration/categories', {}, ['categories']);
  }, [query]);

  useEffect(() => {
    // keep the widget preview at the top of the window when scrolling
    const scrollHandler = () => {
      if (parent.current && widgetPreview.current) {
        const parentRect = parent.current.getBoundingClientRect();
        const offset = 80;
        if (parentRect.top < offset) {
          widgetPreview.current.style.top = `${-1 * parentRect.top + offset}px`;
        } else {
          widgetPreview.current.style.top = '0';
        }
      }
    };
    document
      .querySelector('#scroll-root')
      ?.addEventListener('scroll', scrollHandler);
    return () =>
      document
        .querySelector('#scroll-root')
        ?.removeEventListener('scroll', scrollHandler);
  }, [parent, widgetPreview]);

  const errorText = useCallback(
    fieldName => {
      const requiredFields = ['name'];
      const existingFields = ['slug'];

      const fieldValue = (selectedRetailer as any)[fieldName];

      if (requiredFields.includes(fieldName) && !fieldValue) {
        return t('required');
      }

      if (
        existingFields.includes(fieldName) &&
        spot.data.retailers?.find(
          r =>
            (r as any)[fieldName] === fieldValue &&
            r.id !== selectedRetailer?.id
        )
      ) {
        return t('retailerExists');
      }
      return undefined;
    },
    [t, selectedRetailer, spot]
  );

  const hasError = useCallback(
    fieldName => {
      return !!errorText(fieldName);
    },
    [errorText]
  );

  const validate = useCallback(
    (retailerObj: ExtendedRetailer) => {
      return !Object.keys(retailerObj)
        .map(fieldName => hasError(fieldName))
        .includes(true);
    },
    [hasError]
  );

  const queryPricePlan = useCallback(async () => {
    setLoading(true);
    await query(`retailer/${selectedRetailer.slug}/price-plan`, {}, [
      'pricePlan'
    ]);
    setLoading(false);
    setPricePlan(spot.data?.pricePlan);
  }, [query, spot, selectedRetailer.slug]);

  const saveRetailer = useCallback(
    async (retailerObj, widgetConf) => {
      if (retailerObj && widgetConf && validate(retailerObj)) {
        const retailerData = {
          ...retailerObj,
          widgetConfiguration: { ...widgetConf }
        };
        try {
          const savedRetailer = await raw(
            `retailer/${retailerObj.id > 0 ? retailerObj.id : ''}`,
            {
              method: retailerObj.id > 0 ? 'PATCH' : 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(retailerData)
            }
          );

          if (retailerObj.slug) {
            await query(`retailer/slug/${retailerObj.slug}`, {}, [
              'extendedRetailer'
            ]);
          } else {
            navigate(`/retailer/${savedRetailer?.slug}`);
          }
        } catch (e) {
          displayErrors(e as Error[]);
        }
      }
    },
    [raw, query, validate, displayErrors, navigate]
  );

  const saveBilling = useCallback(
    async (billingObj?: BillingDetails) => {
      const billingData = {
        ...billingObj
      };
      await command(
        `retailer/${selectedRetailer.slug}/price-plan`,
        billingData,
        {
          method: 'POST'
        }
      );
      await queryPricePlan();
    },
    [command, selectedRetailer, queryPricePlan]
  );

  const fetchLocalization = useCallback(async () => {
    setLoading(true);
    const languages = Object.keys(
      widgetConfiguration?.localizationOverrides ?? {}
    );
    const localizationInternal: Record<string, Record<string, string>> = {};
    while (!!languages.length) {
      const language = languages.shift()!;

      const overrideFile =
        widgetConfiguration?.localizationOverrides?.[language]?.replace(
          getCdnPath(),
          getS3Path()
        ) ?? '';

      if (overrideFile) {
        const flattened = {};
        try {
          const response = await fetch(overrideFile);
          const locJson = await response.json();

          flattenLocJson(flattened, locJson);

          localizationInternal[language] = flattened;
        } catch (e) {}
      }
    }

    setLocalization(localizationInternal);
    setLoading(false);
  }, [widgetConfiguration, setLocalization, setLoading]);

  const saveLocalization = useCallback(async () => {
    const languages = Object.keys(localization);
    const localizationOverrides =
      widgetConfiguration?.localizationOverrides ?? {};

    while (!!languages.length) {
      const language = languages.shift();
      if (!language) {
        break;
      }

      let fileSuffix = 0;
      const currentFile =
        widgetConfiguration?.localizationOverrides?.[language];
      if (currentFile) {
        const match = currentFile.match(/(\d+)\.json$/);
        if (match) {
          fileSuffix = parseInt(match[1]);
        }
      }
      fileSuffix = (fileSuffix + 1) % 5;

      const urlResult = await raw<{ url: string; cdnUrl: string }>(
        `retailer/${selectedRetailer.slug}/file-upload-url/${language}-${fileSuffix}.json`
      );

      const url = urlResult?.url;

      if (url) {
        try {
          const inflated = {};
          inflateLocJson(localization[language], inflated);

          const uploadResponse: Response = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(inflated)
          });

          if (uploadResponse.ok) {
            const localizationUrl = new URL(url);
            const cdnPath =
              urlResult.cdnUrl ?? `${getCdnPath()}${localizationUrl.pathname}`;

            localizationOverrides[language] = cdnPath;
          }
        } catch (e) {
          displayErrors(e as Error[]);
        }
      }
    }

    updateWidgetConfiguration({
      ...widgetConfiguration,
      localizationOverrides: {
        ...localizationOverrides
      }
    });
  }, [selectedRetailer, widgetConfiguration, localization]);

  const save = async () => {
    setLoading(true);
    switch (tab) {
      case 'localization':
        await saveLocalization();
        break;
      case 'pricePlan':
        await saveBilling(pricePlan);
        break;
    }

    await saveRetailer(selectedRetailer, widgetConfiguration);
    setLoading(false);
  };

  const cancelRetailerEdit = useCallback(
    async (retailerObj, widgetConf) => {
      if (retailerObj && widgetConf) {
        await query(`retailer/slug/${retailerObj.slug}`, {}, [
          'extendedRetailer'
        ]);
        updateRetailer(spot.data.extendedRetailer);
        updateWidgetConfiguration(
          spot.data.extendedRetailer?.widgetConfiguration
        );
      }
    },
    [query, spot, updateRetailer, updateWidgetConfiguration]
  );

  const removeCategory = useCallback(
    async (retailerObj: ExtendedRetailer, category: string) => {
      if (retailerObj) {
        updateRetailer({
          ...retailerObj,
          categories:
            retailerObj?.categories?.filter(
              c => c.toLowerCase() !== category.toLowerCase()
            ) ?? []
        });
      }
    },
    [updateRetailer]
  );

  const removeBrand = useCallback(
    async (retailerObj: ExtendedRetailer, brand: Brand) => {
      if (retailerObj) {
        updateRetailer({
          ...retailerObj,
          brands: retailerObj?.brands?.filter(b => b.id !== brand.id) ?? []
        });
      }
    },
    [updateRetailer]
  );

  const handleLogoUpload = useCallback(
    async (retailerObj: ExtendedRetailer, file: File) => {
      if (retailerObj) {
        const urlResult = await raw<{ url: string }>(
          `retailer/${retailerObj.slug}/logo-upload-url/${file.name}`
        );
        const url = urlResult?.url;

        if (url) {
          try {
            const uploadResponse: Response = await fetch(url, {
              method: 'PUT',
              body: file
            });

            if (uploadResponse.ok) {
              // TODO: use CDN url
              const logoUrl = new URL(url);
              const logoUrlClean = `${logoUrl.origin}${logoUrl.pathname}`;

              updateRetailer({
                ...retailerObj,
                logoS3: null
              });

              updateRetailer({
                ...retailerObj,
                logoS3: `${logoUrlClean}`
              });
            }
          } catch (e) {
            displayErrors(e as Error[]);
          }
        }
      }
    },
    [raw, updateRetailer, displayErrors]
  );

  const onBrandChosen = useCallback(
    (event: unknown, newValue: Brand | null) => {
      if (newValue) {
        updateRetailer({
          ...selectedRetailer,
          brands: [
            ...(selectedRetailer.brands ?? []).filter(
              b => b.id !== newValue.id
            ),
            newValue
          ]
        });
      }
    },
    [selectedRetailer, updateRetailer]
  );

  const onCategoryChosen = useCallback(
    (event: unknown, category) => {
      if (category) {
        updateRetailer({
          ...selectedRetailer,
          categories: [
            ...(selectedRetailer.categories ?? []).filter(
              c => c.toLowerCase() !== category.toLowerCase()
            ),
            category
          ]
        });
      }
    },
    [selectedRetailer, updateRetailer]
  );

  useEffect(() => {
    if (tab === 'pricePlan') {
      queryPricePlan();
    } else if (tab === 'localization') {
      fetchLocalization();
    }
  }, [tab]);

  return (
    <>
      <CenteredRow>
        <Typography variant="h4">
          {spot.data?.retailers?.length > 1 && (
            <IconButton onClick={onBack} size="large">
              <BackIcon />
            </IconButton>
          )}
          {selectedRetailer && selectedRetailer?.name !== '' ? (
            <TitleField
              value={selectedRetailer.name}
              onChange={(event: React.ChangeEvent<{ value: string }>) => {
                updateRetailer({
                  ...selectedRetailer,
                  name: event.target.value
                });
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
            />
          ) : (
            t('newRetailer')
          )}
        </Typography>
        <Spacer />
        <Button
          disabled={!validate(selectedRetailer) || loading}
          variant="contained"
          color="primary"
          onClick={save}
          startIcon={<SaveIcon />}
        >
          {selectedRetailer.slug ? t('save') : t('create')}
        </Button>
        <Gap size={1} />
        {selectedRetailer.slug && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() =>
              cancelRetailerEdit(selectedRetailer, widgetConfiguration)
            }
            startIcon={<CancelIcon />}
          >
            {t('reset')}
          </Button>
        )}
        <Gap size={1} />
      </CenteredRow>
      <Line />
      <Row>
        <Container>
          {selectedRetailer.id <= 0 && (
            <TextField
              fullWidth
              label={t('name')}
              required
              error={hasError('name')}
              helperText={errorText('name')}
              onChange={(event: React.ChangeEvent<{ value: string }>) =>
                updateRetailer({
                  ...selectedRetailer,
                  name: event.target.value
                })
              }
              value={selectedRetailer.name}
            />
          )}
          {selectedRetailer.slug && (
            <Tabs
              selected={tab}
              onTabChange={setTab}
              loading={loading}
              tabs={{
                basicConfiguration: (
                  <Row>
                    <StyledFormSection>
                      <Typography variant="h5">{t('newUiPromo')}</Typography>
                      <Gap />
                      <Typography variant="subtitle1">
                        {t('newUiPromoText')}
                      </Typography>
                      <Gap />
                      <CenteredRow>
                        <PromoImageContainer>
                          <PromoImage src={newUiPromo} alt="New UI" />
                        </PromoImageContainer>
                        <NewUiToggleContainer>
                          <Typography variant="h6">
                            {!!selectedRetailer.features?.uiRefresh2024
                              ? t('newUiActive')
                              : t('newUiInactive')}
                          </Typography>
                          <Gap size={4} />
                          {!!selectedRetailer.features?.uiRefresh2024 ? (
                            <ToggleButton
                              variant="contained"
                              color="warning"
                              size="large"
                              startIcon={<CancelIcon />}
                              onClick={() => {
                                updateRetailer({
                                  ...selectedRetailer,
                                  features: {
                                    ...selectedRetailer.features,
                                    uiRefresh2024: false
                                  }
                                });
                              }}
                            >
                              {t('deactivate')}
                            </ToggleButton>
                          ) : (
                            <ToggleButton
                              variant="contained"
                              color="primary"
                              size="large"
                              startIcon={<ActivateIcon />}
                              onClick={() => {
                                updateRetailer({
                                  ...selectedRetailer,
                                  features: {
                                    ...selectedRetailer.features,
                                    uiRefresh2024: true
                                  }
                                });
                              }}
                            >
                              {t('tryNow')}
                            </ToggleButton>
                          )}
                        </NewUiToggleContainer>
                      </CenteredRow>
                    </StyledFormSection>
                    <StyledFormSection>
                      <Row>
                        <HalfWidthContainer>
                          <FormSectionTitle>{t('storeSetup')}</FormSectionTitle>
                          {isAdmin() && (
                            <FormRow>
                              <FormLabel>
                                {t(isAdmin() ? 'addBrandToRetailer' : 'brands')}
                              </FormLabel>
                              <ChipsFieldWrapper>
                                {isAdmin() && (
                                  <>
                                    <Autocomplete
                                      fullWidth
                                      onChange={onBrandChosen}
                                      value={null}
                                      options={data?.brands ?? []}
                                      getOptionLabel={(r: Brand) => r.name}
                                      isOptionEqualToValue={(
                                        r1: Brand,
                                        r2: Brand
                                      ) => r1.id === r2.id}
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          margin="normal"
                                          label={t(
                                            selectedRetailer.brands.length
                                              ? 'addBrandToRetailer'
                                              : 'brandRequired'
                                          )}
                                          error={
                                            !selectedRetailer.brands.length
                                          }
                                        />
                                      )}
                                      clearOnBlur
                                      clearOnEscape
                                    />
                                    <Filler />
                                  </>
                                )}
                                {selectedRetailer.brands?.map(brand => (
                                  <Fragment key={brand.id}>
                                    <StyledChip
                                      label={brand.name}
                                      onClick={
                                        isAdmin()
                                          ? () =>
                                              navigate(`/brand/${brand.slug}`)
                                          : undefined
                                      }
                                      onDelete={
                                        isAdmin()
                                          ? () =>
                                              removeBrand(
                                                selectedRetailer,
                                                brand
                                              )
                                          : undefined
                                      }
                                    />
                                  </Fragment>
                                ))}
                              </ChipsFieldWrapper>
                            </FormRow>
                          )}

                          {isAdmin() && (
                            <>
                              <Gap size={1} />
                              <FormRow>
                                <FormLabel>
                                  {t('forceSingleBrandStore')}
                                </FormLabel>
                                <FormFieldWrapper>
                                  <Switch
                                    checked={
                                      widgetConfiguration?.forceSingleBrand
                                    }
                                    onChange={event => {
                                      updateWidgetConfiguration({
                                        ...widgetConfiguration,
                                        forceSingleBrand: event.target.checked
                                      });
                                    }}
                                  />
                                </FormFieldWrapper>
                              </FormRow>
                            </>
                          )}
                          {isAdmin() &&
                            widgetConfiguration?.forceSingleBrand &&
                            selectedRetailer.brands.length > 1 && (
                              <Alert severity="error">
                                {t('singleBrandWarning')}
                              </Alert>
                            )}
                          {isAdmin() && (
                            <>
                              <Gap size={1} />
                              <FormRow>
                                <FormLabel>{t('categories')}</FormLabel>
                                <ChipsFieldWrapper>
                                  {isAdmin() && (
                                    <>
                                      <Autocomplete
                                        fullWidth
                                        freeSolo
                                        onChange={onCategoryChosen}
                                        value={null}
                                        options={
                                          data?.categories?.map(c => c.name) ??
                                          []
                                        }
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            margin="normal"
                                            label={t('addCategoryToRetailer')}
                                          />
                                        )}
                                        clearOnBlur
                                        clearOnEscape
                                      />
                                      <Filler />
                                    </>
                                  )}
                                  {selectedRetailer.categories?.map(
                                    category => (
                                      <Fragment key={category}>
                                        <StyledChip
                                          label={category}
                                          onDelete={
                                            isAdmin()
                                              ? () =>
                                                  removeCategory(
                                                    selectedRetailer,
                                                    category
                                                  )
                                              : undefined
                                          }
                                        />
                                      </Fragment>
                                    )
                                  )}
                                </ChipsFieldWrapper>
                              </FormRow>
                            </>
                          )}
                          <Gap size={1} />
                          <FormRow>
                            <FormLabel>{t('retailerUrl')}</FormLabel>
                            <FormFieldWrapperWide>
                              <NonWrappingRow>
                                <TextField
                                  fullWidth
                                  onChange={(
                                    event: React.ChangeEvent<{ value: string }>
                                  ) => {
                                    updateRetailer({
                                      ...selectedRetailer,
                                      url: event.target.value
                                    });
                                  }}
                                  value={selectedRetailer.url ?? ''}
                                  InputProps={{
                                    endAdornment: (
                                      <IconButton
                                        disabled={!selectedRetailer.url}
                                        onClick={() => {
                                          let enteredUrl = selectedRetailer.url;
                                          if (!enteredUrl) {
                                            return;
                                          }
                                          if (
                                            enteredUrl.length &&
                                            !enteredUrl.startsWith('http')
                                          ) {
                                            enteredUrl = `https://${enteredUrl}`;
                                          }

                                          openInNewTab(enteredUrl);
                                        }}
                                        size="medium"
                                      >
                                        <PopoutIcon />
                                      </IconButton>
                                    )
                                  }}
                                />
                              </NonWrappingRow>
                            </FormFieldWrapperWide>
                          </FormRow>
                          <Gap size={1} />
                        </HalfWidthContainer>
                        <PreviewBar>
                          <Paper variant="outlined" style={{ fontSize: 0 }}>
                            {selectedRetailer.logoS3 ||
                            selectedRetailer.logo ? (
                              <img
                                width={200}
                                height={200}
                                src={
                                  selectedRetailer.logoS3 ??
                                  selectedRetailer.logo ??
                                  ''
                                }
                                alt={t('retailerLogo')}
                                style={{ objectFit: 'scale-down' }}
                              />
                            ) : (
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                style={{ width: 200, height: 200 }}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    variant="overline"
                                    align="center"
                                    display="block"
                                  >
                                    {t('noLogo')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </Paper>
                          <Row>
                            <Box p={1}>
                              <FileInputButton
                                buttonText={t('upload')}
                                onFileSelected={file =>
                                  handleLogoUpload(selectedRetailer, file)
                                }
                              />
                            </Box>
                            <Box
                              p={1}
                              display={
                                selectedRetailer.logoS3 || selectedRetailer.logo
                                  ? 'block'
                                  : 'none'
                              }
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  updateRetailer({
                                    ...selectedRetailer,
                                    logoS3: null,
                                    logo: null
                                  })
                                }
                              >
                                {t('delete')}
                              </Button>
                            </Box>
                          </Row>
                        </PreviewBar>
                      </Row>
                    </StyledFormSection>

                    <ProductFeedConfiguration
                      retailer={selectedRetailer}
                      updateRetailer={updateRetailer}
                      displayErrors={displayErrors}
                    />
                    <Gap size={1} />

                    <StyledFormSection>
                      <Row>
                        <HalfWidthContainer>
                          <Suspense fallback={<CircularProgress />}>
                            <WidgetConfigurationBasic
                              retailerSlug={selectedRetailer.slug}
                              updateWidgetConfiguration={
                                updateWidgetConfiguration
                              }
                              widgetConfiguration={widgetConfiguration}
                              isAdmin={isAdmin}
                              features={selectedRetailer.features}
                            />
                          </Suspense>
                        </HalfWidthContainer>
                        <PreviewBar ref={parent}>
                          <StickyWrapper ref={widgetPreview}>
                            <LivePreview
                              widgetConfiguration={widgetConfiguration ?? {}}
                              extendedRetailer={selectedRetailer}
                            />
                          </StickyWrapper>
                        </PreviewBar>
                      </Row>

                      {isAdmin() && (
                        <>
                          <Gap size={1} />
                          <FormSectionTitle>
                            {t('stepConfiguration')}
                          </FormSectionTitle>
                          <StepConfigFormSection>
                            <StepConfiguration retailer={selectedRetailer} />
                          </StepConfigFormSection>
                        </>
                      )}
                      <ButtonlessUpsell onClick={handleButtonlessUpsell}>
                        <h3>{t('moreStyling1')}</h3>
                        <Trans
                          i18nKey="moreStyling2"
                          components={[<StyledLink key="link" />]}
                        />
                      </ButtonlessUpsell>
                      <Gap size={2} />
                    </StyledFormSection>
                    <Gap size={2} />
                  </Row>
                ),
                localization:
                  selectedRetailer.features?.localizationEditor || isAdmin() ? (
                    <Suspense fallback={<CircularProgress />}>
                      <LocalizationEditor
                        selectedRetailer={selectedRetailer}
                        updateLocalization={setLocalization}
                        localization={localization}
                        loading={loading}
                      />
                    </Suspense>
                  ) : null,
                widgetEffects:
                  selectedRetailer.features?.effects ?? true ? (
                    <Suspense fallback={<CircularProgress />}>
                      <WidgetEffects
                        widgetConfiguration={widgetConfiguration}
                        updateWidgetConfiguration={updateWidgetConfiguration}
                      />
                    </Suspense>
                  ) : null,

                widgetIntegration: (
                  <Suspense fallback={<CircularProgress />}>
                    <WidgetIntegration
                      extendedRetailer={selectedRetailer}
                      updatePlatform={platform =>
                        updateRetailer({ ...selectedRetailer, platform })
                      }
                      widgetConfiguration={widgetConfiguration}
                      updateAddToCartJS={addToCartJS =>
                        updateWidgetConfiguration({
                          ...widgetConfiguration,
                          addToCartJS
                        })
                      }
                    />
                  </Suspense>
                ),
                advancedSettings: isAdmin() ? (
                  <Suspense fallback={<CircularProgress />}>
                    <AdvancedSettings
                      updateFeatures={features =>
                        updateRetailer({ ...selectedRetailer, features })
                      }
                      selectedRetailer={selectedRetailer}
                      updateWidgetConfiguration={updateWidgetConfiguration}
                      widgetConfiguration={widgetConfiguration}
                    />
                  </Suspense>
                ) : null,
                pricePlan: isAdmin() ? (
                  <Suspense fallback={<CircularProgress />}>
                    <PricePlan
                      extendedRetailer={selectedRetailer}
                      pricePlan={pricePlan}
                      updatePricePlan={setPricePlan}
                      updateFeatures={features =>
                        updateRetailer({ ...selectedRetailer, features })
                      }
                    />
                  </Suspense>
                ) : null
              }}
            />
          )}
        </Container>
        <Gap size={5} />
      </Row>
    </>
  );
}
