import React, {
  useContext,
  useState,
  lazy,
  Suspense,
  useRef,
  useCallback,
  useMemo,
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, CircularProgress } from '@mui/material';
import moment from 'moment';
import styled from '@emotion/styled';

import {
  Row,
  Spacer,
  Tabs,
  DateBox,
  DateRangeOption,
  SegmentValue,
  SegmentSelector,
  Segment,
  Gap
} from 'components';
import {
  useSpot,
  useTabFromQuerystring,
  RetailerContext,
  RoleContext
} from 'framework';
import { ExperimentStats } from './experiment-stats';
import { useLocation } from 'react-router-dom';

const GlobalStats = lazy(() => import('./global-stats'));
const DataExport = lazy(() => import('./data-export'));

const StoreStatus = lazy(() => import('./store-status'));
const ReturnsCsvImport = lazy(() => import('./returns-import'));
const Realtime = lazy(() => import('./realtime'));
const EnrichedOrdersOverview = lazy(() => import('./enriched-orders-overview'));

const GlobalStatsFallback = styled(Box)`
  height: 3800px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const DateBoxWrapper = styled(Box)`
  width: 300px;
`;

export function Landing() {
  const { t } = useTranslation();
  const { spot, loading } = useSpot();

  const { hasRole } = useContext(RoleContext);

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const [startDate, setStartDate] = useState<moment.Moment>(
    moment.utc(
      queryParams.get('startDate') ??
        moment.utc().subtract(1, 'month').startOf('month')
    )
  );
  const [endDate, setEndDate] = useState<moment.Moment>(
    moment.utc(
      queryParams.get('endDate') ??
        moment.utc().subtract(1, 'month').endOf('month')
    )
  );
  const dateRange = useRef<DateRangeOption>(
    (queryParams.get('dateRange') ?? 'lastMonth') as DateRangeOption
  );

  const [segment, setSegment] = useState<Segment>('all');
  const [segmentValue, setSegmentValue] = useState<SegmentValue>('all');

  const handleSegmentChange = useCallback(
    (segment: Segment, value: SegmentValue) => {
      setSegment(segment);
      setSegmentValue(value);
    },
    []
  );

  const { retailer } = useContext(RetailerContext);

  const { tab, setTab } = useTabFromQuerystring('stats');

  if (!spot.data.retailers?.length) {
    return <>{t('loading')}</>;
  }

  useEffect(() => {
    if (
      !hasRole(['admin', 'analytics-admin']) &&
      !retailer?.features?.dashboardSegmentation
    ) {
      setSegment('all');
      setSegmentValue('all');
    }
  }, [retailer, hasRole]);

  const setDateRange = useCallback(
    (newDateRange: DateRangeOption) => (dateRange.current = newDateRange),
    [dateRange]
  );

  return (
    <>
      <Row>
        <Box>
          <Typography variant="h4">{t('statistics')}</Typography>
        </Box>
        <Spacer />
        <FilterWrapper>
          <DateBoxWrapper>
            <DateBox
              disabled={loading}
              onEndChange={setEndDate}
              onStartChange={setStartDate}
              onRangeChange={setDateRange}
              defaultRange={dateRange.current}
              defaultStart={startDate}
              defaultEnd={endDate}
            />
          </DateBoxWrapper>
          {(hasRole(['admin', 'analytics-admin']) ||
            retailer?.features?.dashboardSegmentation) && (
            <>
              <Gap size={1} />
              <SegmentSelector
                segments={['gender', 'newDevice', 'device']}
                currentSegment={segment}
                currentSegmentValue={segmentValue}
                onSegmentChange={handleSegmentChange}
                retailer={retailer}
                loading={loading}
              />
            </>
          )}
        </FilterWrapper>
      </Row>
      <Tabs
        selected={tab}
        onTabChange={setTab}
        hideIfSingle
        tabs={{
          stats: (
            <Suspense
              fallback={
                <GlobalStatsFallback>
                  <CircularProgress />
                </GlobalStatsFallback>
              }
            >
              <GlobalStats
                startDate={startDate}
                endDate={endDate}
                retailer={retailer}
                dateRange={dateRange.current}
                segment={segment}
                segmentValue={segmentValue}
              />
            </Suspense>
          ),
          realtime: hasRole(['admin', 'analytics-admin']) ? (
            <Suspense
              fallback={
                <GlobalStatsFallback>
                  <CircularProgress />
                </GlobalStatsFallback>
              }
            >
              <Realtime retailer={retailer} />
            </Suspense>
          ) : null,
          dataExport: hasRole(['admin']) ? (
            <>
              <Suspense fallback={<CircularProgress />}>
                <DataExport
                  startDate={startDate}
                  endDate={endDate}
                  retailer={retailer}
                />
              </Suspense>
              <Suspense fallback={<CircularProgress />}>
                <ReturnsCsvImport />
              </Suspense>
            </>
          ) : null,
          enrichedOrdersOverview: hasRole(['admin']) ? (
            <>
              <Suspense fallback={<CircularProgress />}>
                <EnrichedOrdersOverview
                  startDate={startDate}
                  endDate={endDate}
                />
              </Suspense>
            </>
          ) : null,
          experimentStats: hasRole(['admin']) ? (
            <>
              <Suspense fallback={<CircularProgress />}>
                <ExperimentStats
                  startDate={startDate}
                  endDate={endDate}
                  retailer={retailer}
                />
              </Suspense>
            </>
          ) : null,
          storeStatus: hasRole(['admin']) ? (
            <Suspense fallback={<CircularProgress />}>
              <StoreStatus />
            </Suspense>
          ) : null
        }}
      />
    </>
  );
}
