import React from 'react';
import {
  Box,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import {
  GroupsRounded as AllIcon,
  LocalOfferRounded as BrandIcon,
  WcRounded as GenderIcon,
  DevicesRounded as MobileIcon,
  FiberNewRounded as NewDeviceIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { BrandSelector, Gap, Row, Spacer } from 'components';
import { Brand, RetailerItem } from 'framework';
import styled from '@emotion/styled';

export type Segment = 'all' | 'brand' | 'gender' | 'newDevice' | 'device';
export type GenderSegment = 'all' | 'male' | 'female';
export type NewDeviceSegment = 'all' | 'newDevice' | 'returningDevice';
export type DeviceSegment = 'all' | 'mobile' | 'desktopTablet';
export type SegmentValue =
  | GenderSegment
  | NewDeviceSegment
  | DeviceSegment
  | Brand
  | null;

interface SegmentSelectorProps {
  segments: Segment[];
  currentSegment: Segment;
  currentSegmentValue: SegmentValue;
  retailer: RetailerItem | null;
  loading: boolean;
  onSegmentChange: (segment: Segment, value: SegmentValue) => void;
}

const SelectWrapper = styled(Box)`
  flex: 0 0 186px;
`;

export function SegmentSelector({
  segments,
  currentSegment,
  currentSegmentValue,
  onSegmentChange,
  retailer,
  loading
}: SegmentSelectorProps) {
  const { t } = useTranslation();

  const handleSegmentChange = (
    _event: React.MouseEvent<HTMLElement>,
    newSegment: Segment | null
  ) => {
    if (newSegment) {
      // Reset the value when changing the segment with some defaults for each segment
      let newValue: any = 'all';
      if (newSegment === 'brand') newValue = null;
      else if (newSegment === 'gender') newValue = 'all' as GenderSegment;
      else if (newSegment === 'newDevice') newValue = 'all' as NewDeviceSegment;
      else if (newSegment === 'device') newValue = 'all' as DeviceSegment;

      onSegmentChange(newSegment, newValue);
    }
  };

  return (
    <Box style={{ width: 360 }}>
      {segments.length > 1 && (
        <Row>
          <Spacer />
          <ToggleButtonGroup
            value={currentSegment}
            exclusive
            onChange={handleSegmentChange}
            aria-label="segment"
          >
            <ToggleButton value="all" aria-label="all" title={t('all')}>
              <AllIcon />
            </ToggleButton>
            {segments.includes('brand') && (
              <ToggleButton value="brand" aria-label="brand" title={t('brand')}>
                <BrandIcon />
              </ToggleButton>
            )}
            {segments.includes('gender') && (
              <ToggleButton
                value="gender"
                aria-label="gender"
                title={t('gender')}
              >
                <GenderIcon />
              </ToggleButton>
            )}
            {segments.includes('newDevice') && (
              <ToggleButton
                value="newDevice"
                aria-label="newDevice"
                title={t('newDevice')}
              >
                <NewDeviceIcon />
              </ToggleButton>
            )}
            {segments.includes('device') && (
              <ToggleButton
                value="device"
                aria-label="device"
                title={t('device')}
              >
                <MobileIcon />
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Row>
      )}
      {segments.length > 1 && <Gap size={1} />}
      <Row>
        <Spacer />
        {currentSegment === 'brand' && (
          <BrandSelector
            selectedBrand={currentSegmentValue as Brand | null}
            includeAllBrands
            onBrandChanged={brand => onSegmentChange('brand', brand)}
            retailer={retailer}
            disabled={loading}
          />
        )}
        <SelectWrapper>
          {currentSegment === 'gender' && (
            <Select
              value={currentSegmentValue as GenderSegment}
              onChange={event =>
                onSegmentChange('gender', event.target.value as GenderSegment)
              }
              disabled={loading}
              fullWidth
            >
              <MenuItem value="all">{t('all')}</MenuItem>
              <MenuItem value="male">{t('male')}</MenuItem>
              <MenuItem value="female">{t('female')}</MenuItem>
            </Select>
          )}
          {currentSegment === 'newDevice' && (
            <Select
              value={currentSegmentValue as NewDeviceSegment}
              onChange={event =>
                onSegmentChange(
                  'newDevice',
                  event.target.value as NewDeviceSegment
                )
              }
              disabled={loading}
              fullWidth
            >
              <MenuItem value="all">{t('all')}</MenuItem>
              <MenuItem value="newDevice">{t('newDevice')}</MenuItem>
              <MenuItem value="returningDevice">
                {t('returningDevice')}
              </MenuItem>
            </Select>
          )}
          {currentSegment === 'device' && (
            <Select
              value={currentSegmentValue as DeviceSegment}
              onChange={event =>
                onSegmentChange('device', event.target.value as DeviceSegment)
              }
              disabled={loading}
              fullWidth
            >
              <MenuItem value="all">{t('all')}</MenuItem>
              <MenuItem value="mobile">{t('mobile')}</MenuItem>
              <MenuItem value="desktopTablet">{t('desktopTablet')}</MenuItem>
            </Select>
          )}
        </SelectWrapper>
      </Row>
    </Box>
  );
}
